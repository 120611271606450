<template>
  <v-tooltip bottom open-delay="500" :disabled="disableTooltip">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <router-link class="sku" :to="buildItemUrl(item)" v-text="shortenedText" />
      </div>
    </template>
    <span> {{ text }} </span>
  </v-tooltip>
</template>

<script>
import { buildItemUrl, shorten } from '@/helpers/formatters';

const MAX_LENGTH = 100500;

export default {
  props: {
    item: { type: Object, required: true },
    field: { type: String, default: 'sku' },
  },
  computed: {
    text() {
      return this.item?.[this.field] ?? '';
    },
    disableTooltip() {
      return this.text.length <= MAX_LENGTH;
    },
    shortenedText() {
      return shorten(this.text, MAX_LENGTH);
    },
  },
  methods: {
    buildItemUrl(item) {
      if (item) return buildItemUrl(item);
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.sku {
  text-decoration: underline;
  cursor: pointer;
  color: hsl(0, 0%, 0%);

  &:hover {
    color: var(--v-primary-base);
  }
}
</style>
