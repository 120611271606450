import { getSymbolFromCurrency } from '@/helpers/currencySymbols';

const getCurrencyFromPrices = (items) => items.find((item) => item.currency)?.currency ?? null;

export const getPricesRange = (arr = [], field = 'price') => arr
  .map((w) => w[field])
  .filter((p) => ![null, undefined, ''].includes(p))
  .map((el) => parseFloat(el).toFixed(2))
  .sort((a, b) => a - b)
  .map((el) => el.toString().replace('.', ','));

export const withCurrencySymbol = (value, currency) => {
  if (!value) return '';

  const symbol = getSymbolFromCurrency(currency);

  return `${String(value)}&nbsp;${symbol}`;
};

export const formatNumberRange = (arr = []) => {
  if (!Array.isArray(arr) || !arr.length) return '';
  if (arr.length > 1) return `${arr[0]} – ${arr[arr.length - 1]}`;
  return arr[0];
};

export const normalizePrice = (price = 0) => price.toFixed(2).toString().replace('.', ',');

export const formatPrice = (arr = [], field = 'price') => {
  const currency = getCurrencyFromPrices(arr);
  const prices = getPricesRange(arr, field);
  const pricesRange = formatNumberRange(prices);

  if (!pricesRange.length) return '';

  return withCurrencySymbol(pricesRange, currency);
};

export const buildProductUrl = (item, key) => `/products/${item?.[key]}/`;

export const buildItemUrl = (item) => `/items/${item?.id}/?search=${item?.name}`;

export const buildParsaProductUrl = (id, params = {}) => {
  let url = `https://parsa.gdml.ru/products/${id}`;

  const { region } = params;
  if (region) {
    url = `${url}?region=${region}`;
  }
  return url;
};

export const shorten = (text = '', length = 9) => {
  if (text.length <= length) return text;
  const lengthStart = length / 2;
  const lengthEnd = text.length - (length / 2 - 2);
  return `${text.substring(0, lengthStart)}..${text.substring(lengthEnd)}`;
};
