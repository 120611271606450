<template>
  <div class="article d-flex justify-end align-center">
    <router-link class="article__text mr-1" :to="`/products/${gmid}`">
      {{ gmid }}
    </router-link>
    <v-tooltip bottom open-delay="800">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <a class="article__icon" style="text-decoration: none" :href="parsaLink" target="_blank">
            <v-icon small> mdi-open-in-new </v-icon>
          </a>
        </div>
      </template>
      <span> Открыть этот товар в Парсе </span>
    </v-tooltip>
  </div>
</template>

<script>
import { buildParsaProductUrl } from '@/helpers/formatters';

export default {
  props: {
    gmid: { type: String, required: true },
    regionSlug: { type: String, default: null },
  },
  computed: {
    parsaLink() {
      return buildParsaProductUrl(this.gmid, { region: this.regionSlug });
    },
  },
};
</script>
<style lang="scss" scoped>
.article {

  &__text {
    text-decoration: underline;
    color: hsl(0, 0%, 0%);

      &:hover {
        color: var(--v-primary-base);
      }
  }

  &__icon {
      &:hover {
        color: var(--v-primary-base);
      }
  }
}
</style>
